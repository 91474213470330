.bg-primary-25 {
  --bs-bg-opacity: 0.25;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-50 {
  --bs-bg-opacity: 0.5;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.text-bg-light {
  background-color: var(--bs-gray-300) !important;
}

.bg-light {
  background-color: var(--bs-gray-300) !important;
}

pre {
  /* We want to these long texts to wrap so they look nice in preview and print */
  text-wrap: balance;
}
